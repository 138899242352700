<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors }"
    class="option-group"
  >
    <h2 v-if="label" class="label" :class="{ 'label--invalid': errors.length }">
      {{ label || name }}
    </h2>

    <div class="options">
      <option-button
        v-for="(option, index) in options"
        :key="index"
        :name="groupName"
        :option="option"
        :picked="picked"
        @change="$emit('change', $event)"
      />
    </div>

    <span v-if="errors.length" class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import OptionButton from "./OptionButton.vue";

export default {
  name: "Option",

  model: {
    prop: "picked",
    event: "change"
  },

  components: { OptionButton, ValidationProvider },

  props: {
    options: Array,

    picked: [Object, String],

    rules: {
      default: "",
      type: [String, Object]
    },

    name: {
      type: String,
      default: ""
    },

    groupName: String,
    label: String
  }
};
</script>

<style scoped>
.options {
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
  justify-content: center;
}

.label {
  width: fit-content;
  margin: 0 auto var(--lengthLg1);
  font-weight: var(--medium);
  text-align: center;
  background-color: white;
}
</style>
