<template>
  <label class="radio" :class="{ 'radio--checked': isChecked }">
    <input
      type="radio"
      :value="option"
      :checked="isChecked"
      :name="name"
      @change="onChange(option)"
      class="radio__input"
      tabindex="0"
    />

    <div class="radio__radio">
      <font-awesome-icon :icon="checkIcon" size="xs" class="radio__check" />
    </div>

    {{
      option instanceof Object
        ? option.value || option.name || option.text
        : option
    }}
  </label>
</template>

<script>
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "OptionButton",

  model: {
    prop: "picked",
    event: "change"
  },

  data: () => ({
    checkIcon: faCheck
  }),

  props: {
    option: [Object, String],
    name: String,
    picked: [Object, String]
  },

  computed: {
    isChecked() {
      return this.option instanceof Object
        ? this.option.id === this.picked.id
        : this.option === this.picked;
    }
  },

  methods: {
    onChange(option) {
      this.$emit("change", option);
    }
  }
};
</script>

<style scoped>
.radio {
  flex-basis: 45%;
  display: flex;
  align-items: center;
  padding: var(--lengthMd1);
  font-weight: var(--medium);
  background-color: white;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
  cursor: pointer;
  transition: background-color 150ms;
}

.radio__radio {
  width: var(--lengthMd3);
  height: var(--lengthMd3);
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--lengthSm2);
  background-color: var(--grayColor1);
  border-radius: 50%;
  transition: background-color 150ms;
}

.radio__check {
  color: var(--grayColor1);
}

.radio:hover .radio__radio,
.radio__radio:focus {
  border-color: var(--mainColor1);
  outline: none;
}

.radio__input {
  display: none;
}

.radio:hover {
  border-color: var(--mainColor1);
}

.radio--checked {
  color: white;
  background-color: var(--mainColor1);
  border-color: var(--mainColor1);
}

.radio--checked .radio__check {
  color: var(--mainColor1);
}

.radio--checked .radio__radio {
  background-color: white;
}
</style>
