<template>
  <ValidationObserver class="preview" tag="div" v-slot="{ invalid }">
    <form v-if="!loading" @submit.prevent="next" class="survey-form">
      <header class="header">
        <logo size="sm" />

        <div class="btn-container">
          <custom-button variant="secondary" type="button" @click="restart">
            <font-awesome-icon :icon="icons.restart" slot="icon" />
            Volver a Empezar
          </custom-button>
          <custom-button
            variant="secondary"
            type="button"
            @click="$router.push('/settings')"
          >
            Salir
          </custom-button>
        </div>
      </header>

      <transition name="question" mode="out-in">
        <template v-for="(question, index) in surveyForm">
          <template v-if="currentStep === index">
            <ENPSScale
              v-if="question.sectionId === 'enps'"
              :key="index"
              v-model="question.answer"
              :label="question.question.text"
            />
            <option-group
              v-else-if="typeof question.answer === 'object'"
              :key="index"
              v-model="question.answer"
              :label="question.question.text"
              :groupName="question.question.text"
              :options="scale"
              rules="required"
            />

            <div v-else :key="index">
              <h2 class="label">{{ question.question.text }} (Opcional)</h2>
              <custom-textarea
                v-model="question.answer"
                placeholder="Escriba aquí"
                class="textarea"
              />
            </div>
          </template>
        </template>
      </transition>

      <div class="navigation">
        <custom-button type="button" variant="secondary" @click="previous">
          <font-awesome-icon :icon="icons.previous" slot="icon" />
          Anterior
        </custom-button>

        <progress-bar :total="surveyForm.length" :progress="currentStep + 1" />

        <custom-button
          type="submit"
          variant="primary"
          :disabled="invalid || !(currentStep < surveyForm.length - 1)"
        >
          <template v-if="currentStep < surveyForm.length - 1">
            <font-awesome-icon :icon="icons.next" slot="append-icon" />
            Siguiente
          </template>

          <template v-else>
            <font-awesome-icon :icon="icons.send" slot="append-icon" />
            Enviar
          </template>
        </custom-button>
      </div>
    </form>

    <loading-screen v-else />
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { ValidationObserver } from "vee-validate";

import CustomButton from "../../components/CustomButton.vue";
import ProgressBar from "../../components/ProgressBar.vue";
import OptionGroup from "../../components/OptionGroup.vue";
import Logo from "../../components/Logo.vue";
import LoadingScreen from "../../components/LoadingScreen.vue";
import ENPSScale from "../../components/ENPSScale.vue";

import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faPaperPlane,
  faRedo
} from "@fortawesome/free-solid-svg-icons";
import CustomTextarea from "../../components/CustomTextarea.vue";

export default {
  components: {
    ValidationObserver,
    CustomButton,
    ProgressBar,
    OptionGroup,
    Logo,
    LoadingScreen,
    ENPSScale,
    CustomTextarea
  },

  data: () => ({
    currentStep: 0,
    surveyForm: [],
    loading: false,

    icons: {
      send: faPaperPlane,
      previous: faLongArrowAltLeft,
      next: faLongArrowAltRight,
      restart: faRedo
    }
  }),

  computed: {
    ...mapState({
      questions: state => state.sections.questions,
      scale: state => state.scale.scale,
      survey: state => state.survey
    })
  },

  methods: {
    ...mapActions("sections", ["fetchQuestions"]),

    ...mapActions("scale", ["fetchScale"]),

    previous() {
      this.setStep(this.currentStep - 1);
    },

    next() {
      if (this.currentStep + 1 === this.surveyForm.length) {
        this.$refs.thankYouMessage.openModal();
        return;
      }

      this.setStep(this.currentStep + 1);
    },

    setStep(value) {
      if (typeof value !== "number") return;
      if (value < 0) return;
      if (value >= this.surveyForm.length) return;
      this.currentStep = value;
    },

    restart() {
      this.currentStep = 0;
    }
  },

  async mounted() {
    try {
      this.loading = true;
      await this.fetchQuestions();
      await this.fetchScale();

      this.surveyForm = this.questions.map(q => {
        let answer;
        if (q.section.id === "enps") answer = 5;
        else if (q.isOpenQuestion) answer = "";
        else answer = { id: "", text: "" };

        return {
          question: {
            id: q.id,
            text: q.text
          },
          sectionId: q.section.id,
          answer
        };
      });

      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style scoped>
.preview {
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%230054B4' fill-opacity='0.14'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  overflow-x: hidden;
}

.label {
  width: fit-content;
  margin: 0 auto var(--lengthLg1);
  font-size: var(--lengthMd3);
  font-weight: var(--medium);
  color: var(--fontColor1);
  text-align: center;
  background-color: white;
}

.textarea {
  max-width: 768px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
}

.header .btn-container {
  margin-left: auto;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.survey-form {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigation {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "previous-btn bar next-btn";
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
  align-items: center;
  padding: var(--lengthSm3);
  background-color: white;
  border-radius: var(--lengthSm1);
  box-shadow: 0 0 2px 1px rgba(000, 000, 000, 0.1);
}

.navigation .btn:first-child {
  grid-area: previous-btn;
}

.navigation .btn:last-child {
  grid-area: next-btn;
}

.navigation .bar {
  grid-area: bar;
}

.question-enter {
  transform: translateX(2rem);
  opacity: 0;
}

.question-enter-active,
.question-leave-active {
  transition: transform 150ms linear, opacity 150ms linear;
}

.question-enter-to {
  transform: translateX(0);
}

.question-leave-to {
  transform: translateX(-2rem);
  opacity: 0;
}
</style>
