<template>
  <div class="bar">
    <div class="progress" :style="{ width: calculateProgress + '%' }"></div>
    <div
      class="indicator"
      :style="{ left: `calc(${calculateProgress}% - var(--lengthSm2))` }"
    ></div>
    <div
      class="dialog"
      :style="{ left: `calc(${calculateProgress}% - var(--lengthSm2))` }"
    >
      {{ `${progress} de ${total}` }}
      <span class="triangle"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",

  props: {
    progress: Number,
    total: Number
  },

  computed: {
    calculateProgress() {
      return (this.progress / this.total) * 100;
    }
  }
};
</script>

<style scoped>
.bar {
  position: relative;
  height: var(--lengthSm1);
  background-color: var(--grayColor1);
  border-radius: var(--lengthSm2);
}

.progress {
  height: 100%;
  background-color: var(--mainColor2);
  border-radius: var(--lengthSm2);
  transition: width 300ms;
}

.indicator {
  position: absolute;
  top: calc((var(--lengthSm3) - var(--lengthSm1)) / -2);
  left: 0;
  width: var(--lengthSm3);
  height: var(--lengthSm3);
  background-color: var(--mainColor1);
  border-radius: 50%;
  transition: left 300ms;
}

.dialog {
  position: absolute;
  bottom: 300%;
  left: 0;
  padding: var(--lengthSm1) var(--lengthSm2);
  color: white;
  font-size: 0.75rem;
  background-color: var(--fontColor1);
  border-radius: 4px;
  visibility: hidden;
  transition: visibility 150ms;
}

.triangle {
  position: absolute;
  top: 100%;
  left: calc(var(--lengthSm3) / 2);
  height: 7.5px;
  width: 10px;
  display: block;
  background-color: inherit;
  clip-path: polygon(0 0, 100% 0, 0% 100%);
}

.indicator:hover + .dialog {
  visibility: visible;
}
</style>
